import React from 'react'
import { ButtonContent } from '../global/boton/ButtonContent'

const CallToAction = ({ title, texts, images }) => {
  return (
    <section
      className='svgTriangulotopBgWhite svgTriangulotop w-full h-full bg-cover bg-no-repeat bg-center relative pt-10 pb-20 flex justify-center items-center'
      style={{
        backgroundImage: `url("${images}")`,
        backgroundAttachment: 'fixed'

      }}
    >
      <div className='flex justify-center items-center w-[90%] md:w-[97%] mx-auto h-full relative svgTriangulotopBG'
        data-aos="zoom-in"
      >
        <div className='w-4/5 mx-auto flex flex-col items-center text-center gap-10 relative text-white pt-28 pb-16'>
          <h1 className='text-[30px] md:text-[50px]'>{title}</h1>
          <p>{texts}</p>
          <div>
            <ButtonContent btnclases={'botonWhite'} btnStyle={'three'} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToAction